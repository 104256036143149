import {
    ANALYSIS_DESI_ALL,
    ANALYTICS_FILTER_SUCCESS,
    ANALYSIS_DESI_SINGLE,
    FETCH_START,
    FETCH_END,
    ANALYSIS_DESI_LIST_SUCCESS,
} from '../../../actions/CalculationDesigner/AnalyticsDesigner/ActionTypes';
const initialState = {
    AnalysisData: [],
    AnalyticsFilters:[],
    AnalysisDataSingle: [],
    loading: false,
    AnalysisDropdownData:[],
}
const AnalyticsDesignerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return {
                loading: true
            };
        case FETCH_END:
            return {
                loading: false
            };
        case ANALYSIS_DESI_ALL:
            return {
                ...state,
                AnalysisData: action.data
            };
            case ANALYTICS_FILTER_SUCCESS:
                return {
                    ...state,
                    AnalyticsFilters: action.data
                };
        case ANALYSIS_DESI_SINGLE:
            return {
                ...state,
                AnalysisDataSingle: action.data
            };
        case ANALYSIS_DESI_LIST_SUCCESS:
            return {
                ...state,
                AnalysisDropdownData: action.data,
                loading: false
            };
        default:
            return state;
    }
}
export default AnalyticsDesignerReducer;
