import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import axios from "axios";
import axiosAuthRefresh from "axios-auth-refresh";
import secureLocalStorage from "react-secure-storage";
import { getEnv } from "./env.js";
import { toast } from "react-toastify";

//  const demoUrl = "https://demo-api.ima360.app/v1/api/"; //local purposegit
// const localBaseUrl = "http://3.22.165.7:8080/v1/api/"; //local purposegit
//const localBaseUrl = "https://stage-api.ima360.app/v1/api/"; //local purposegit
var baseUrl = getEnv();
// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: baseUrl["BASE_URL"],
  // baseURL: localBaseUrl,
  // baseURL: demoUrl,
});
// Define the refresh token logic
const refreshAccessToken = async (failedRequest) => {
  // Get the refresh token from your preferred storage mechanism
  const refreshToken = secureLocalStorage.getItem("rFtk");

  try {
    // Make a request to the server to refresh the access token
    const response = secureLocalStorage.getItem('user_type') === 'okta' ?
      await axiosInstance.post("okta/token", {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      })
      : await axiosInstance.post("refresh", {
        refresh_token: refreshToken,
      });

    // Update the access token
    const newAccessToken = response.data.response.access_token;
    localStorage.setItem("accessToken", newAccessToken);

    // Retry the original request with the new access token
    failedRequest.response.config.headers.Authorization = `Bearer ${newAccessToken}`;
    return axios(failedRequest.response.config);
  } catch (error) {
    // Handle the error when refreshing the token
    return Promise.reject(error);
  }
};

// Add a request interceptor to include the access token in the headers
axiosInstance.interceptors.request.use((config) => {
  // Get the access token from your preferred storage mechanism
  const accessToken = localStorage.getItem("accessToken");

  // Set the access token in the headers
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

// Add a response interceptor to handle token expiration or any other response-related logic
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle the response data or perform any modifications
    return response;
  },
  (error) => {
    // Check if the error is due to an expired token (e.g., 401 Unauthorized)
    if (error.response.status === 401) {
      // Trigger the token refresh logic
      return refreshAccessToken(error).catch((refreshError) => {
        // Handle the error when refreshing the token
        return Promise.reject(refreshError);
      });
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      )
        toast.error(error.response.data.error.message);
      else {
        if (
          error.response &&
          error.response.data &&
          error.response.data &&
          error.response.data.complete_error_message
        )
          toast.error(error.response.data.complete_error_message);
      }
    }

    // Handle other error cases
    return Promise.reject(error);
  }
);

export default axiosInstance;
